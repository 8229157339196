<template>
<div class='card'>
  <TabView v-model:activeIndex='activeTabIndex'>
    <TabPanel :header="t['246']">
      <h5 class='font-bold hr-line-dashed-bottom'>{{ t['470'] }}</h5>
      <div class='p-fluid grid'>
        <div class="field col-12 md:col-4 mt-2">
          <span class="p-float-label">
            <InputText id="idInput" type="text" :disabled='true' v-model='idValue'/>
            <label for="idInput" class='font-light'>{{ t['466'] }}</label>
          </span>
        </div>
        <div class='col-12 md:col-12'></div>
        <div class="field col-12 hr-line-bottom">
          <span class="p-float-label">
            <Textarea id='commentInput' v-model='commentValue' :autoResize='true' style='width: 100%'></Textarea>
            <label for="commentInput" class='font-bold'>{{ t['447'] }}</label>
          </span>
        </div>
        <div class='col-12 ml-0'>
          <h5 class='font-bold hr-line-dashed-bottom'>{{ t['471'] }}</h5>
          <div class='hr-line-dashed-bottom'>
            <div class='ml-2 mb-2'>
              <Button icon="pi" iconPos="right" style='font-size: 1px; width: 15px' class='p-button-danger'/>
              <span class='font-light' style='font-size: 12px'>{{ '   ' + t['463'] }}</span>
              <Button icon="pi" iconPos="right" style='font-size: 1px; width: 15px' class='p-button-warning ml-2'/>
              <span class='font-light' style='font-size: 12px'>{{ '   ' + t['464'] }}</span>
              <Button icon="pi" iconPos="right" style='font-size: 1px; width: 15px' class='p-button-success ml-2'/>
              <span class='font-light' style='font-size: 12px'>{{ '   ' + t['465'] }}</span>
            </div>
          </div>
          <div class='dashboard'>
            <div class='task-list'>
              <ul>
                <li v-for='trans in tableTranslationList' :key='trans.tid' style='border-bottom: none; padding: 0px 0px;'>
                  <div class="p-inputgroup md:col-6">
                    <span class="p-inputgroup-addon" style='list-style-type: none;'>{{trans.langCode}}</span>
                    <InputText placeholder="Website" v-model='trans.name' style='width: 50%'/>
                    <Button v-bind:class="[trans.status === 1 ? 'p-button-danger' : trans.status === 2 ? 'p-button-warning' : 'p-button-success']" @click='trans.status = ((trans.status)%3)+1'></Button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class='mt-2'>
        <Button icon='pi pi-save' :label="t['87']" class='mr-2' @click='updateTableValues'></Button>
        <Button icon='pi pi-times' :label="t['86']" class='p-button-outlined' @click="backToTables"></Button>
      </div>
    </TabPanel>
    <TabPanel :header="t['247']">
      <h5 class='font-bold hr-line-dashed-bottom'>{{ t['249'] }}</h5>
      <DataTable :value='tableModelList' :loading='loading' class='p-datatable-sm' v-model:selection='modelSelection' selectionMode='single'>
        <Column field='mid' :header="t['252']"></Column>
        <Column field='name' :header="t['116']"></Column>
        <Column field='comment' :header="t['447']"></Column>
        <Column>
          <template #body='slotProps'>
            <div style='text-align: center'>
              <i class='pi pi-pencil' @click='openEditModel(slotProps.data.tid, slotProps.data.mid)'  style='font-size: 1rem'></i>
            </div>
          </template>
        </Column>
      </DataTable>
    </TabPanel>
    <TabPanel :header="t['248']">
      <h5 class='font-bold hr-line-dashed-bottom'>{{ t['250'] }}</h5>
      <div class='md:col-12 text-right'>
          <Button :label="t['251']" @click="$router.replace('/addParameter/tid/' + tableId)"></Button>
      </div>
      <DataTable :value='tableRecordList' :loading='loading' class='p-datatable-sm'
                 :resizableColumns='true' columnResizeMode='fit'
                 v-model:selection='parameterSelection' selectionMode='single'>
        <Column field='rid' :header="t['493']" headerStyle='width: 5%'></Column>
        <Column field='num1' :header="t['475']" headerStyle='width: 8%'></Column>
        <Column field='num2' :header="t['476']" headerStyle='width: 8%'></Column>
        <Column field='num3' :header="t['477']" headerStyle='width: 8%'></Column>
        <Column field='num4' :header="t['478']" headerStyle='width: 8%'></Column>
        <Column field='vac1' :header="t['479']" headerStyle='width: 19%'></Column>
        <Column field='vac2' :header="t['480']" headerStyle='width: 19%'></Column>
        <Column field='dat1' :header="t['481']" headerStyle='width: 10%'></Column>
        <Column field='dat2' :header="t['482']" headerStyle='width: 10%'></Column>
<!--        <Column field='comment' :header="t['447']"></Column>-->
        <Column headerStyle='width: 5%'>
            <template #body='slotProps'>
              <div style='text-align: center'>
                <i class='pi pi-pencil' @click='openEditRecord(slotProps.data.tid, slotProps.data.mid, slotProps.data.rid)'  style='font-size: 1rem'></i>
              </div>
            </template>
        </Column>
      </DataTable>
    </TabPanel>
  </TabView>
</div>
</template>

<script>
import { useStore } from 'vuex';
import { usePrimeVue } from 'primevue/config';
import { computed } from 'vue';
import axios from 'axios';

export default {
  name: 'EditTables',
  setup() {
    const store = useStore()
    const primeVue = usePrimeVue()

    return {
      token: computed(() => store.state.bearerToken),
      t: primeVue.config.locale,
    }
  },
  data() {
    return {
      tableId: this.$route.params.parameterTableId,
      loading: true,
      idValue: null,
      commentValue: null,
      tableTranslationList: [],
      tableModelList: [],
      tableRecordList: [],
      activeTabIndex: 0,
      modelSelection: null,
      parameterSelection: null,
    }
  },
  computed: {

  },
  created() {

  },
  mounted() {
    this.getTableValues()
    let index = Number(sessionStorage.getItem('parametersTabIndex'))
    this.activeTabIndex = (index) ? index : 2
  },
  methods: {
    getTableValues() {
      this.loading = true
      axios
          .get( '/administration/param/table/' + this.tableId + '/get',  {
            headers: {
              Authorization: this.token
            }
          })
          .then( res => {
            if(res.status === 200 && res.data.rmsList[0].retCode === 0){
              this.tableTranslationList = res.data.retObj.paramTableTranslations
              this.tableModelList = res.data.retObj.paramModels
              this.tableRecordList = res.data.retObj.paramRecords
              this.idValue = res.data.retObj.tid
              this.commentValue = res.data.retObj.comment
              this.loading = false
            }else {
              this.$toast.add({severity:'error', summary: 'Error', detail:res.data.rmsList[0].retMsg, life: 3000})
            }
          })
          .catch(error => {
            console.log("There was an error loading text ID list!")
            console.log(error)
          })
    },
    updateTableValues() {
      axios
          .post('/administration/param/table/' + this.tableId + '/update', {
            'tid': this.idValue,
            'comment': this.commentValue,
            'paramTableTranslations': this.tableTranslationList
          }, {
            headers: {
              Authorization: this.token
            }
          })
          .then(res => {
            if(res.status === 200 && res.data.rmsList[0].retCode === 0) {
              setTimeout(() => {
                this.$toast.add({severity:'success', summary: 'Success!', detail:res.data.rmsList[0].retMsg, life: 3000})
              }, 500)
              this.$router.replace('/paramTables')
            }else {
              this.$toast.add({severity:'error', summary: 'Error', detail:res.data.rmsList[0].retMsg, life: 3000})
            }
          })
          .catch(error  => {
            console.log(error);
          })
    },
    openEditRecord(tid, mid, rid) {
      this.$router.push('/editParameter/tid/' + tid + '/mid/' + mid + '/rid/' + rid)
    },
    openEditModel(tid, mid) {
      this.$router.push('/editParamModel/tid/' + tid + '/mid/' + mid)
    },
    backToTables() {
        this.$router.replace('/paramTables')
    }
  },
  watch: {
    '$route'(to) {
      this.tableId = to.params.parameterTableId
    },
    activeTabIndex: function() {
        sessionStorage.setItem('parametersTabIndex', this.activeTabIndex)
    }
  },
};
</script>

<style scoped lang='scss'>

.hr-line-dashed-bottom {
  border-bottom: 1px dashed lightgray;
}

</style>